<template lang="html">
  <div
    class="z-10 absolute w-module bg-white"
    style="top: 100px; width: 477px; height: 87%; overflow: hidden"
  >
    <el-card
      style="overflow: hidden"
      class="z-30 m-auto box-card user-card pl-8"
    >
      <el-form
        class="label"
        :label-position="`top`"
        :size="`big`"
        ref="emailRole"
        :rules="rules"
        :model="user"
        pooper-class="label"
      >
        <el-form-item
          class="fontSource font-semibold"
          prop="email"
          :label="$t('email')"
        >
          <el-input
            class="inp"
            v-model="user.email"
            type="text"
            :placeholder="$t('users.email_placeholder')"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          class="fontSource font-semibold"
          prop="role"
          :label="$t('users.invite_level_access')"
        >
          <el-select class="w-full" v-model="user.role" type="text">
            <el-option
              v-for="item in allowedRoles"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="flex justify-between pb-5">
        <skif-button @click="invite('emailRole')">{{
          $t('user.invite_user')
        }}</skif-button>
        <skif-button variant="normal" @click="$emit('close')">{{
          $t('cancel')
        }}</skif-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import { usersApi, companyApi } from '@/api'
import { allRoles, accessByRole } from '@/constants'

export default {
  props: {
    title: Number
  },

  data() {
    const emailRegExp =
      /^[-a-z0-9!#$%&'*+/=?^_`{|}~]+(?:\.[-a-z0-9!#$%&'*+/=?^_`{|}~]+)*@(?:[a-z0-9]([-a-z0-9]{0,61}[a-z0-9])?\.)*(?:aero|arpa|asia|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|com|ru|[a-z][a-z])$/
    const validateEmail = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$t('loginPage.errors.emailError')))
      }
      if (!emailRegExp.test(value.toLowerCase())) {
        return callback(new Error(this.$t('loginPage.errors.emailError')))
      }
      callback()
    }
    const validateRole = (rule, value, callback) => {
      const roleErrorMessage = this.$t('user.invite_role_error')
      if (value === '') {
        callback(new Error(roleErrorMessage))
      }
      callback()
    }
    return {
      allowedRoles: [],
      user: {
        email: '',
        role: ''
      },
      rules: {
        email: [{ validator: validateEmail, trigger: 'change' }],
        role: [{ validator: validateRole, trigger: 'change' }]
      }
    }
  },
  computed: {
    ...mapState({
      roles: (state) => state.properties.roles,
      currentRole: (state) => state.login.me.role.key
    }),
    header() {
      return this.title === 1
        ? this.$t('user.invite_user')
        : this.$t('user.invite_all')
    }
  },
  methods: {
    ...mapActions({
      getAllRoles:'accessSettings/getAllRoles'
    }),
    async initializeAllowedRoles() {
      // current user access level
      // const currentRoleAccess = accessByRole[this.currentRole]
      // const roleByNameOptions = this.roles.reduce(
      //   (acc, role) => ({ ...acc, [role.key]: role }),
      //   {}
      // )
      //
      // // set default role
      // this.user.role = allRoles.NO_ACCESS
      // // set allowed roles depending on current role access level
      // this.allowedRoles = currentRoleAccess.map(
      //   (roleKey) => roleByNameOptions[roleKey]
      // )
      this.allowedRoles = await this.getAllRoles().then(res=>res.map(r=>{
        return {
          id:r.id,
          key:r.key,
          value:r.value
        }
      }))
    },
    invite(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const role = this.allowedRoles.filter(r=>r.id===this.user.role)[0]
          const formData = {
            email: this.user.email,
            role: role
          }
          this.title === 1
            ? usersApi.invite(
                formData,
                (response) => {
                  this.$emit('close')

                  this.$showSuccess(response.data.message)
                },
                (error) => {
                  this.$showError(error.response.data.message)
                }
              )
            : companyApi.inviteAll(
                { formData },
                (response) => {
                  this.$showSuccess(response.data.message)
                  this.$emit('close')
                },
                (error) => {
                  this.$showError(error.response.data.message)
                }
              )
        } else {
          return false
        }
      })
    }
  },

  mounted() {
    this.initializeAllowedRoles()
  }
}
</script>
<style>
.label label {
  color: #91b4e7;
  font-size: 16px;
}
.user-card {
  height: 500px;
  box-shadow: none !important;
  border: none;
}
.user-card .el-card__body {
  padding-left: 0 !important;
}
.inp input::placeholder {
  color: #677785;
  font-weight: bold;
}
</style>
